import isChromatic from 'chromatic/isChromatic';

import NEXT from '~/constants/NEXT';
import { isStorybookEnv } from '~/helpers';

const ENV: NodeJS.ProcessEnv =
  // eslint-disable-next-line no-nested-ternary
  isStorybookEnv || isChromatic()
    ? ({ APP_VERSION: 'Storybook' } as unknown as NodeJS.ProcessEnv)
    : NEXT.IS_SERVER
    ? // grab env variables from node on the server
      process.env
    : // grab env variables from `window` on the client
      ((window as unknown as Record<string, unknown>).__ENV__ as NodeJS.ProcessEnv);

export default ENV;
