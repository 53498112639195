import { trimStart } from 'lodash';

import { ROUTES_REGEX } from '~/constants/ROUTES';

const extractPath = (path: string): string => {
  return path.split('?')[0].split('#')[0];
};

export const normalizePath = (path?: string): string => {
  return path ? trimStart(extractPath(path).toLowerCase(), '/') : '';
};

export const isPathEquivalent = (path1?: string, path2?: string): boolean => {
  return normalizePath(path1) === normalizePath(path2);
};

export const isRegexPathEquivalent = (paramaterPath: string, actualPath: string): boolean => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const re = new RegExp(`^${paramaterPath}$`, 'g');
  return !!extractPath(actualPath).match(re);
};

export const trimAnchor = (path: string): string => {
  if (!path.includes('#')) return path;
  const pathParts = path.split('#');
  if (!pathParts[1].includes('?')) return pathParts[0];
  return `${pathParts[0]}?${pathParts[1].split('?')[1]}`;
};

export const isPathValidRoute = (path: string): boolean => {
  return Object.keys(ROUTES_REGEX).some(routeKey =>
    isRegexPathEquivalent(ROUTES_REGEX[routeKey as keyof typeof ROUTES_REGEX], path),
  );
};
