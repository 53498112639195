import { useReactiveVar } from '@apollo/client';
import { IReactNativeObj, NAMES } from 'encourage-ecosystem-lib';

import { showSafeAreaVar } from '~/buses/debugMenu';

const reactNativeDummy: IReactNativeObj = {
  build: {
    buildNumber: 'dummy_buildNumber',
    marketingVersion: 'dummy_marketingVersion',
  },
  notification: {
    fcmToken: 'dummy_token',
  },
  platform: {
    model: 'dummy_model',
    OS: 'ios',
    screenHeight: 123,
    screenOrientation: 'landscape',
    screenWidth: 300,
    Version: 'dummy_version',
  },
  safeAreaInsets: { bottom: 45, left: 0, right: 0, top: 60 },
};

// eslint-disable-next-line import/prefer-default-export
export const useReactNativeObj = () => {
  const debugShowSafeArea = useReactiveVar(showSafeAreaVar);

  if (debugShowSafeArea) return reactNativeDummy;

  return typeof window !== 'undefined' ? window[NAMES.REACT_NATIVE_OBJ] : undefined;
};
