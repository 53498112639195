/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

/** Colors from E4S Web Design System */
// https://www.figma.com/file/4JazmqwcfRVZyRq6vRkf05/E4S-Web---Design-System?type=design&node-id=2946-25441&mode=design&t=5RBrs8ilrgqPDW2x-4

// TODO: fill in empty strings
const colors = {
  surface: {
    white: '',
    grayscale: {
      lightestGray: '',
      lightGray: '',
      midGray: '#BDBDBD',
      neutralGray: '#828282',
      darkGray: '',
      black: '#222222',
    },
    black: {
      30: '#D6DDE5',
    },
  },
  sapphire: {
    15: '#DEE6F5',
    30: '#BECEEA',
    50: '#92ADDC',
    70: '#668CCF',
    100: '#255BBA',
  },
  navy: {
    15: '',
    30: '',
    50: '',
    70: '',
    100: '#003057',
  },
  seafoam: {
    15: '',
    30: '',
    50: '',
    70: '',
    100: '',
  },
  neon: {
    15: '#FEFBE3',
    30: '',
    50: '',
    70: '',
    100: '#F9E547',
  },
  orange: {
    15: '',
    30: '',
    50: '',
    70: '',
    100: '',
  },
  fuchsia: {
    15: '',
    30: '',
    50: '',
    70: '',
    100: '',
  },
  mist: {
    15: '',
    30: '',
    50: '',
    70: '',
    100: '',
  },
  teal: {
    15: '',
    30: '',
    50: '',
    70: '',
    100: '',
  },
  utility: {
    danger: '',
    tealT0: '',
    tealT3: '',
    savedCollegeHeartFill: '',
    dangerAlertFill: '',
    blueAlertFill: '',
    successAlertFill: '',
    successAlert: '',
  },
};
export default colors;
