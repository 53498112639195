import React from 'react';

// Given a getToken function, return a reactive copy of the token state
// Prefer to use the getToken function over this!
const useAuthReactiveTokens = (getTokenFn: () => string | null | undefined) => {
  const [token, setToken] = React.useState<string | null | undefined>(getTokenFn());

  const setState = React.useCallback(() => {
    // This is performant because if we set state to the same state
    // over and over, React is smart enough to not rerender unless
    // it's set to a NEW state.
    setToken(getTokenFn());
  }, [getTokenFn]);

  React.useEffect(() => {
    window.addEventListener('storage', setState);
    return () => {
      window.removeEventListener('storage', setState);
    };
  }, [setState]);

  return token;
};

export default useAuthReactiveTokens;
