'use client';

import { Alert } from '@actinc/dls';
import { RenderField } from '@craftercms/experience-builder/react';
import { SxProps } from '@mui/material';
import React from 'react';

import { useCMSContext } from '~/client/context/provider';
import { CrafterSpecialNote } from '~/types/models/special-note';

import { StyledSpecialNote } from './styles';

export type SpecialNoteProps = {
  model?: CrafterSpecialNote;
  sx?: SxProps;
};

export const SpecialNote = ({ model, sx }: SpecialNoteProps): React.ReactNode => {
  const { xb } = useCMSContext();

  if (!model) return null;

  return (
    <Alert icon={false} sx={sx}>
      <RenderField
        component={StyledSpecialNote}
        componentProps={{ isAuthoring: xb.isAuthoring }}
        fieldId="body_html"
        model={model}
        render={(data: string): { __html: string } => ({ __html: data })}
        renderTarget="dangerouslySetInnerHTML"
      />
    </Alert>
  );
};
