import { RenderField } from '@craftercms/experience-builder/react';
import { Stack, SxProps } from '@mui/material';

import { CrafterYoutubeVideo } from '~/types/models/youtube-video';

export type YoutubeVideoProps = {
  model: CrafterYoutubeVideo;
  sx?: SxProps;
};

const getVideoId = (fullUrl: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = fullUrl.match(regExp);

  return match?.[7]?.length === 11 ? match[7] : fullUrl;
};

export const YoutubeVideo = ({ model, sx }: YoutubeVideoProps): React.ReactNode => {
  const { url_t: url, title_t: title } = model;

  const embedId = getVideoId(url);
  return (
    <Stack
      sx={{
        aspectRatio: { xs: '4/3', sm: '16/9' },
        ...(sx ?? {}),
      }}
    >
      <RenderField fieldId="title_t" model={model} />
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
        allowFullScreen
        height="100%"
        src={`https://www.youtube.com/embed/${embedId}`}
        title={title}
        width="100%"
      />
      <RenderField fieldId="description_t" model={model} />
    </Stack>
  );
};
