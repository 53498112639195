'use client';

import { crafterConf } from '@craftercms/classes';
import { ElementType, useContext, useMemo } from 'react';

import { CMSContext } from '~/types/cms-context';

import { InternalCMSContext, DEFAULT_CRAFTER_CONTEXT } from './context';

export type CMSClientConfig = {
  crafterAuthoringBaseUrl: string;
  crafterDeliveryBaseUrl: string;
  crafterSiteName: string;
  getAccessToken?: () => string | undefined;
  overrideContentTypeMap?: Record<string, ElementType>;
};

type PropTypes = {
  config: CMSClientConfig;
  children: React.ReactNode;
};

export const CMSProvider = ({ config, children }: PropTypes): React.ReactNode => {
  const ctx: CMSContext | undefined = useMemo(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const host = window.location.host;
    const isOnLocalhost = host.startsWith('localhost') || host.startsWith('127.0.0.1');
    const isOnLocalhostAuthoring = isOnLocalhost && host.endsWith(':8080');
    const isOnRemoteAuthoring = window.origin === config.crafterAuthoringBaseUrl;
    const isOnAuthoring = isOnRemoteAuthoring || isOnLocalhostAuthoring;

    const nextConfig = {
      baseUrl: isOnAuthoring ? config.crafterAuthoringBaseUrl : config.crafterDeliveryBaseUrl,
      site: config.crafterSiteName,
    };

    crafterConf.configure(nextConfig);
    crafterConf.getConfig().fetchConfig = {
      mode: 'cors',
    };

    return {
      isSiteRequired: isOnRemoteAuthoring || isOnLocalhost,
      getAccessToken: config.getAccessToken ?? ((): string | undefined => undefined),
      ...nextConfig,
      xb: {
        isAuthoring: isOnAuthoring,
        isHeadlessMode: true,
      },
      client: {
        contentTypeMap: config.overrideContentTypeMap ? config.overrideContentTypeMap : DEFAULT_CRAFTER_CONTEXT.client.contentTypeMap,
        imageBreakpoints: DEFAULT_CRAFTER_CONTEXT.client.imageBreakpoints,
      },
    };
  }, [config]);

  // On server
  if (!ctx) {
    return children;
  }

  // In browser
  return <InternalCMSContext.Provider value={ctx}>{children}</InternalCMSContext.Provider>;
};

export const useCMSContext = (): CMSContext => useContext(InternalCMSContext);
