import React from 'react';

export enum ScholarshipsTabs {
  MATCHED,
  SAVED,
}

export interface IScholarshipDataContext {
  scholarshipType?: ScholarshipsTabs;
  setScholarshipType: React.Dispatch<React.SetStateAction<undefined | ScholarshipsTabs>>;
}

export const ScholarshipDataContext = React.createContext<IScholarshipDataContext>({
  scholarshipType: undefined,
  setScholarshipType: () => {
    throw new Error('Not implemented');
  },
});

export const ScholarshipDataProvider = ScholarshipDataContext.Provider;

interface IScholarshipDataContextProps {
  children: React.ReactNode;
}

export const ScholarshipDataProviderComponent: React.FC<IScholarshipDataContextProps> = props => {
  const { children } = props;
  const [scholarshipType, setScholarshipType] = React.useState<undefined | ScholarshipsTabs>(undefined);

  return <ScholarshipDataProvider value={{ scholarshipType, setScholarshipType }}>{children}</ScholarshipDataProvider>;
};
