import { BridgeContext, IBridgeMessageTypes } from 'encourage-ecosystem-lib';
import React from 'react';

import { sentryReactNativeBridgeError } from '~/helpers/SentryErrors';

// eslint-disable-next-line import/prefer-default-export
export const useOpenUrlWithBridge = () => {
  const { actions } = React.useContext(BridgeContext);

  return async (href: string): Promise<void> => {
    const bridgeData = {
      url: href,
    };

    try {
      await actions.sendMessage<IBridgeMessageTypes.FROM_WEB_OPEN_LINK>(IBridgeMessageTypes.FROM_WEB_OPEN_LINK, {
        url: href,
      });
    } catch (err) {
      sentryReactNativeBridgeError({ bridgeData, bridgeMessageType: IBridgeMessageTypes.FROM_WEB_OPEN_LINK, error: err });
    }
  };
};
