import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BridgeContext, IBridgeMessagePayloadForType, IBridgeMessageTypes } from 'encourage-ecosystem-lib';
import { noop } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import Logger from '~/helpers/logger';
import { sentryReactNativeBridgeError } from '~/helpers/SentryErrors';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';

export interface IReactNativeFeaturesContext {
  actions: {
    setDefaultPageFeatures: (nativeFeatures: IBridgeMessagePayloadForType[typeof IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES]) => void;
    reinstateDefaultPageFeatures: () => void;
    // eslint-disable-next-line max-len
    /** @deprecated Use `setDefaultPageFeatures` for features set by pages or use `setTemporaryFeatures` for temporary features controlled by ephemeral elements like modals. */
    setNativeFeatures: (nativeFeatures: IBridgeMessagePayloadForType[typeof IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES]) => void;
    setTemporaryFeatures: (nativeFeatures: IBridgeMessagePayloadForType[typeof IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES]) => void;
  };
  state: {
    bottomNavIsVisible?: boolean;
    isReactNative: boolean;
  };
}

export const ReactNativeFeaturesContext = React.createContext<IReactNativeFeaturesContext>({
  actions: {
    reinstateDefaultPageFeatures: noop,
    setDefaultPageFeatures: noop,
    setNativeFeatures: noop,
    setTemporaryFeatures: noop,
  },
  state: { bottomNavIsVisible: false, isReactNative: false },
});

const ReactNativeFeaturesProvider = ReactNativeFeaturesContext.Provider;

const logger = new Logger('ReactNativeFeaturesProviderComponent');

export const ReactNativeFeaturesProviderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { actions } = React.useContext(BridgeContext);
  const reactNativeObj = useReactNativeObj();
  // We want to simulate react native in all places while mocking, except here
  const isActuallyReactNative = !!reactNativeObj && reactNativeObj.platform.Version !== 'dummy_version';

  // TODO: This can be deleted after the MobileBottomNav feature flag is deleted!
  const bottomNavEnabledCache = useRef(false);
  const defaultPageFeaturesCache = useRef<IBridgeMessagePayloadForType[typeof IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES]>({});

  const [bottomNavIsVisible, setBottomNavIsVisible] = useState<boolean | undefined>(false);

  // eslint-disable-next-line no-underscore-dangle
  const _setFeatures = ({
    bottomNavIsVisible: _bottomNavIsVisible,
    statusBarBackgroundColor,
    statusBarScrolledBackgroundColor,
    statusBarScrolledTextStyle,
    statusBarTextStyle,
  }: IBridgeMessagePayloadForType[typeof IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES]) => {
    if (isActuallyReactNative) {
      const bridgeMessage = {
        bottomNavIsVisible: _bottomNavIsVisible,
        statusBarBackgroundColor,
        statusBarScrolledBackgroundColor,
        statusBarScrolledTextStyle,
        statusBarTextStyle,
      };

      setBottomNavIsVisible(_bottomNavIsVisible);
      actions
        .sendMessage<IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES>(IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES, bridgeMessage)
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((...args) => {
          sentryReactNativeBridgeError({
            bridgeData: bridgeMessage,
            bridgeMessageType: IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES,
            error: args,
          });
          logger.error(...args);
        });
    } else {
      setBottomNavIsVisible(isSmallScreen && _bottomNavIsVisible);
      bottomNavEnabledCache.current = !!_bottomNavIsVisible;
    }
  };

  /** Temporarily override the current features */
  const setTemporaryFeatures = _setFeatures;

  // eslint-disable-next-line max-len
  /** @deprecated Use `setDefaultPageFeatures` for features set by pages or use `setTemporaryFeatures` for temporary features controlled by ephemeral elements like modals.  */
  const setNativeFeatures = _setFeatures;

  /** Used on the Page level to revert back to after any temporary changes */
  const setDefaultPageFeatures = (features: IBridgeMessagePayloadForType[typeof IBridgeMessageTypes.FROM_WEB_SET_NATIVE_FEATURES]) => {
    defaultPageFeaturesCache.current = features;
    _setFeatures(features);
  };

  /** Reset function to clear out temporary changes */
  const reinstateDefaultPageFeatures = () => {
    _setFeatures(defaultPageFeaturesCache.current);
  };

  useEffect(() => {
    if (!isActuallyReactNative) setBottomNavIsVisible(isSmallScreen && bottomNavEnabledCache.current);
  }, [isActuallyReactNative, isSmallScreen]);

  return (
    <ReactNativeFeaturesProvider
      value={{
        actions: { reinstateDefaultPageFeatures, setDefaultPageFeatures, setNativeFeatures, setTemporaryFeatures },
        state: { bottomNavIsVisible, isReactNative: isActuallyReactNative },
      }}
    >
      {children}
    </ReactNativeFeaturesProvider>
  );
};
