import Stack from '@mui/material/Stack';
import { BOTTOM_NAV_HEIGHT } from 'encourage-ecosystem-lib';
import { useRouter } from 'next/router';
import React from 'react';

import { ROUTES, ROUTES_REGEX } from '~/constants/ROUTES';
import { isStorybookEnv } from '~/helpers';
import { useReactNativeObj } from '~/hooks/useReactNativeObj';
import ExploreAnimData from '~/public/static/animations/Activate_Explore.json';
import FeedbackAnimData from '~/public/static/animations/Activate_Feedback.json';
import HomeAnimData from '~/public/static/animations/Activate_Home.json';
import ProfileAnimData from '~/public/static/animations/Activate_Profile.json';

import BottomBarItem from './BottomBarItem';
import { StyledBox } from './styles';

export const useBottomBarHeight = () => {
  const reactNativeObj = useReactNativeObj();
  const bottomSafeAreaWeb = 20; // Flexible value
  const bottomSafeArea = reactNativeObj && !isStorybookEnv ? reactNativeObj?.safeAreaInsets?.bottom : bottomSafeAreaWeb;
  return `${BOTTOM_NAV_HEIGHT + bottomSafeArea}px`;
};

const BottomBar: React.FC = () => {
  const router = useRouter();
  const activePath = router.asPath;
  const bottomBarHeight = useBottomBarHeight();

  const items = [
    {
      animationData: HomeAnimData,
      href: ROUTES.INDEX,
      isActive: activePath === ROUTES.INDEX,
      label: 'Home',
    },
    {
      animationData: ProfileAnimData,
      href: ROUTES.PROFILE,
      isActive: activePath.search(ROUTES_REGEX.PROFILE) >= 0,
      label: 'Profile',
    },
    {
      animationData: ExploreAnimData,
      href: ROUTES.EXPLORE,
      isActive: activePath.search(ROUTES_REGEX.EXPLORE) >= 0,
      label: 'Explore',
    },
    {
      animationData: FeedbackAnimData,
      href: ROUTES.FEEDBACK,
      isActive: activePath === ROUTES.FEEDBACK,
      label: 'Feedback',
    },
  ];

  return (
    <StyledBox sx={{ height: bottomBarHeight }}>
      <Stack direction="row" gap={2} justifyContent="space-between" sx={{ pt: 1, px: 3 }}>
        {items.map(item => (
          <BottomBarItem key={item.label} {...item} />
        ))}
      </Stack>
    </StyledBox>
  );
};

export default BottomBar;
