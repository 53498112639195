import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import SigninSplash from '~/components/Animation/SigninSplash';
import { styled } from '~/helpers/material/styled';

export const ParentSplashGrid = styled(Grid)(() => ({
  height: '100%',
  width: '100%',
}));

export const LeftSplashGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  alignItems: 'center',
  background: theme.palette.primary.main,
  display: 'flex',
  flex: '1 1 0',
  height: '100vh',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
}));

export const LeftSplashAnimation = styled(SigninSplash)(() => ({
  height: '100%',
  width: '100%',
}));

export const ContentFrame = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(10),
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(3),
  },
  alignItems: 'center',
  height: '100%',
  maxWidth: 408,
  paddingTop: theme.spacing(3),
}));

export const ContentArea = styled(Grid)(({ theme }) => ({
  flex: '1 1 0',
  marginTop: theme.spacing(3),
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Footer = styled(Box)({
  textAlign: 'center',
  width: '100%',
});
