import { styled } from '@mui/material/styles';

export const StyledSpecialNote = styled('div', { shouldForwardProp: prop => prop !== 'isAuthoring' })<{
  isAuthoring?: boolean;
}>(({ isAuthoring }) => ({
  '& *:not(a)': { pointerEvents: 'none' },

  '& *:has(> a)': {
    pointerEvents: `${isAuthoring ? 'none' : 'auto'}`,
  },
}));
