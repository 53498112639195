'use client';

import { SxProps } from '@mui/material';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import { useResponsiveImage } from '~/client/components/Image/useResponsiveImage';
import { CrafterImage } from '~/types/models/image';

import { ImageView } from './ImageView';

export type ImageProps = {
  model: CrafterImage;
  className?: string;
  sx?: SxProps;
};

export const Image = ({ model, ...props }: ImageProps): React.ReactNode => {
  const { imageSources } = useResponsiveImage(model);
  if (imageSources == null) return null;
  return <ImageView imageSources={imageSources} model={model} {...props} />;
};
