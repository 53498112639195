'use client';

import { RenderField } from '@craftercms/experience-builder/react';
import React from 'react';

import { type Image as ImageSource } from '~/client/components/Image/useResponsiveImage';

import { ImageProps } from './Image';
import { StyledPicture, HeaderContainer, ImageDescription, Title, StyledImage } from './styles';

type Props = {
  imageSources: ImageSource[];
} & ImageProps;

export const ImageView = ({ model, className, sx, imageSources }: Props): React.ReactNode => {
  if (imageSources == null) return null;
  return (
    <StyledPicture sx={sx}>
      {model.title_t && (
        <HeaderContainer>
          <RenderField component={Title} componentProps={{ variant: 'h4' }} fieldId="title_t" model={model} />
        </HeaderContainer>
      )}
      <picture>
        {imageSources.map(image => (
          <source
            key={image.value}
            media={`(min-width: ${image.value}px)`}
            srcSet={`
                    ${image.src1x} 1x,
                    ${image.src2x} 2x
                  `}
          />
        ))}
        <StyledImage alt={model?.alt_text_t ?? ''} className={className} src={imageSources[0]?.src1x} />
      </picture>
      {model.description_t && <RenderField component={ImageDescription} fieldId="description_t" model={model} />}
    </StyledPicture>
  );
};
