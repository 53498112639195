import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React from 'react';

const COOKIE_NAME = 'utm_campaign';

const useMarketingCampaignId = () => {
  const router = useRouter();

  const setMarketingCampaignId = React.useCallback(() => {
    const utmCampaign = router.query.utm_campaign ?? null;

    if (utmCampaign) {
      Cookies.set(COOKIE_NAME, String(utmCampaign), { expires: 1 });
    }
  }, [router.query.utm_campaign]);

  const removeMarketingCampaignId = React.useCallback(() => {
    Cookies.remove(COOKIE_NAME);
  }, []);

  const getMarketingCampaignId = React.useCallback(() => {
    const utmCampaign = Cookies.get(COOKIE_NAME);
    return utmCampaign ?? null;
  }, []);

  return {
    getMarketingCampaignId,
    removeMarketingCampaignId,
    setMarketingCampaignId,
  };
};

export default useMarketingCampaignId;
