'use client';

import { SxProps } from '@mui/material';
import React from 'react';

import { useCMSContext } from '~/client/context/provider';
import { CrafterDocument, DocumentFile } from '~/types/models/document';

import { DocumentView } from './DocumentView';

export type DocumentProps = {
  model: CrafterDocument;
  sx?: SxProps;
};

const getFileName = (filePath: string): string => {
  if (filePath) return filePath.split('/').at(-1) || '';
  return '';
};

export const Document = ({ model, sx }: DocumentProps): React.ReactNode => {
  const { baseUrl } = useCMSContext();
  const file = model?.file_o ? model.file_o[0] : ({} as DocumentFile);
  const filePath = file?.value ?? file?.craftercms?.path;

  if (!filePath) return <div>No documents attached.</div>;

  return <DocumentView fileName={getFileName(filePath)} filePath={`${baseUrl}${filePath}`} model={model} sx={sx} />;
};
