import * as Sentry from '@sentry/nextjs';
import { BridgeContext, IBridgeMessageTypes, NAMES } from 'encourage-ecosystem-lib';
import { get, isString } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

import NEXT from '~/constants/NEXT';
import Logger from '~/helpers/logger';
import { sentryReactNativeBridgeIncomingEventError } from '~/helpers/SentryErrors';

const logger = new Logger('BridgeListener');

const BridgeListener: React.FC = (): null => {
  const { actions, state } = React.useContext(BridgeContext);
  const router = useRouter();

  React.useEffect(() => {
    const bridgeEventListener = async (event: Event | MessageEvent) => {
      try {
        const eventData = get(event, 'data');

        if (isString(eventData)) {
          await actions.receiveStringMessage(eventData);
        }
      } catch (err) {
        sentryReactNativeBridgeIncomingEventError({ error: err, event });
        logger.error(err);
      }
    };

    if (NEXT.IS_CLIENT) {
      // iOS will use window.addEventListener and ignore document.addEventListener
      window.addEventListener('message', bridgeEventListener);
      // Android will use document.addEventListener and ignore window.addEventListener
      document.addEventListener('message', bridgeEventListener);
      actions
        .setSendStringMessage((data): void => {
          window[NAMES.REACT_NATIVE_WEB_VIEW]?.postMessage(data);
        })
        .catch((...args) => {
          Sentry?.captureException(args);
          logger.error(...args);
        });
    }

    return () => {
      if (NEXT.IS_CLIENT) {
        window.removeEventListener('message', bridgeEventListener);
        document.removeEventListener('message', bridgeEventListener);
        actions.setSendStringMessage(undefined).catch((...args) => logger.error(...args));
      }
    };
  }, []);

  const receivedMessage = state.receivedMessages[0];

  React.useEffect(() => {
    const execute = async () => {
      if (receivedMessage) {
        const { id, type, payload } = receivedMessage;

        switch (type) {
          case IBridgeMessageTypes.FROM_NATIVE_NAVIGATE: {
            if (payload.method === 'REPLACE') {
              await router.replace({
                pathname: payload.pathname,
                query: payload.query,
              });
            } else {
              await router.push({
                pathname: payload.pathname,
                query: payload.query,
              });
            }
            await actions.markReceivedMessageAsHandled(id);
            // SEND TO PENDO - mobile bottom navigation change
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            window.pendo.track(`${payload.routeLabel || 'Undefined'} - rn Nav`, {});
            break;
          }
          // no default
        }
      }
    };

    execute().catch((...args) => {
      Sentry?.captureException(args);
      logger.error(...args);
    });
  }, [receivedMessage]);

  return null;
};

export default BridgeListener;
