import { ContentInstance } from '@craftercms/studio-ui/models';
import isError from 'lodash/isError';
import { useEffect, useRef, useState } from 'react';

import { useCMSContext } from '~/client/context/provider';
import { fetchContentInstance, fetchContentInstances } from '~/common/crafter';

/**
 * Represents the response from a Crafter CMS content instance fetch.
 * @template T the type of the content instance expected back from Crafter
 */
export type CrafterContentInstanceResponse<T> = {
  item: T | undefined;
  isLoading: boolean;
  error: Error | null;
};

/**
 * Fetches a content instance from Crafter CMS. If the specified path is not fouund or is not accessible in Crafter,
 * an error is returned.
 * @param path the Crafter path
 */
export const useContentInstance = <T extends ContentInstance = ContentInstance>(
  path: string,
): CrafterContentInstanceResponse<T> => {
  const ctx = useCMSContext();
  const [item, setItem] = useState<T>();
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (path) {
      fetchContentInstance(ctx, path)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(instance => {
          if (instance?.craftercms?.contentTypeId) {
            return setItem(instance as T);
          }
          throw new Error('invalid data returned from fetchContentInstance');
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch(e => setError(isError(e) ? e : new Error(JSON.stringify(e))));
    }
  }, [ctx, path]);
  return { item, isLoading: !item && !error, error };
};

/**
 * Represents the response from a Crafter CMS content instances fetch.
 * Note that the type of items returned in the items array is ContentInstance,
 * as this type supports heterogeneous content types. It is up to the caller
 * to map ContentInstance objects to the corresponding actual type.
 */
export type ContentInstancesResponse = {
  items: ContentInstance[] | undefined;
  isLoading: boolean;
  error: Error | null;
};

/**
 * Fetches multiple content instances from Crafter CMS. If any of the specified paths are not found or are not
 * accessible, an error is returned.
 * @param paths an array of valid accessible Crafter paths
 */
export const useContentInstances = (paths: string[]): ContentInstancesResponse => {
  const ctx = useCMSContext();
  const [items, setItems] = useState<ContentInstance[]>();
  const [error, setError] = useState<Error | null>(null);
  const isCalledRef = useRef(false);
  useEffect(() => {
    if (paths.length > 0 && !isCalledRef.current) {
      fetchContentInstances(ctx, paths)
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(instances => {
          isCalledRef.current = true;
          return setItems(instances);
        })
        .catch(e => {
          isCalledRef.current = true;
          return setError(isError(e) ? e : new Error(JSON.stringify(e)));
        });
    }
  }, [ctx, paths]);
  return { items, isLoading: !items && !error, error };
};
