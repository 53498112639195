import MuiGlobalStyles from '@mui/material/GlobalStyles';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { ReactNativeFeaturesContext } from '~/context/ReactNativeFeatures';

import { useBottomBarHeight } from '../BottomBar';

interface IProps {
  classNameAlert: string;
  classNameAlertsContainer: string;
}

const GlobalStyles: React.FC<IProps> = ({ classNameAlert, classNameAlertsContainer }: IProps) => {
  const {
    state: { bottomNavIsVisible },
  } = React.useContext(ReactNativeFeaturesContext);

  const bottomBarHeight = useBottomBarHeight();
  const theme = useTheme();
  return (
    <MuiGlobalStyles
      styles={() => ({
        [`.${classNameAlert}`]: {
          boxShadow: theme.shadows[1],
        },
        [`.${classNameAlertsContainer}`]: bottomNavIsVisible
          ? {
              marginBottom: bottomBarHeight,
            }
          : undefined,
      })}
    />
  );
};

export default GlobalStyles;
