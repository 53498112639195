import { ApolloError, ApolloQueryResult, OperationVariables, QueryResult } from '@apollo/client';
import React from 'react';

export type RefetchFunction<TData, TVariables> = (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>;

export enum FiniteState {
  Idle,
  Loading,
  Error,
  Ready,
}

export interface IIdleQueryState<TData = unknown, TVariables = OperationVariables> {
  data: null;
  error: null;
  loading: false;
  refetch: RefetchFunction<TData, TVariables>;
  state: FiniteState.Idle;
}

export interface ILoadingQueryState<TData, TVariables = OperationVariables> {
  data: TData | null;
  error: null;
  loading: true;
  refetch: RefetchFunction<TData, TVariables>;
  state: FiniteState.Loading;
}

export interface IErrorQueryState<TData = unknown, TVariables = OperationVariables> {
  data: null;
  error: ApolloError;
  loading: false;
  refetch: RefetchFunction<TData, TVariables>;
  state: FiniteState.Error;
}

export interface IReadyQueryState<TData, TVariables = OperationVariables> {
  data: TData;
  error: null;
  loading: false;
  refetch: RefetchFunction<TData, TVariables>;
  state: FiniteState.Ready;
}

export type FiniteQueryState<T, U = OperationVariables> =
  | IIdleQueryState<T, U>
  | ILoadingQueryState<T, U>
  | IErrorQueryState<T, U>
  | IReadyQueryState<T, U>;

/**
 * Reduces a query result state to a finite state descriptor.
 *
 * This is useful as it allows TypeScript to make assertions about the state when
 * doing verifications on its fields, and thus narrowing the type of the state to
 * one of its known finite states.
 *
 * It also reduces the number of tests necessary, since only a few combination of
 * values are possible, determined by the possible finite states.
 */

export function useToFiniteState<T, U extends OperationVariables = OperationVariables>(
  result: QueryResult<T, U>,
): FiniteQueryState<T, U> {
  const { data, error, loading, previousData, refetch } = result;

  if (loading) {
    return {
      data: previousData ?? null,
      error: null,
      loading,
      refetch,
      state: FiniteState.Loading,
    };
  }

  if (error) {
    return {
      data: null,
      error,
      loading: false,
      refetch,
      state: FiniteState.Error,
    };
  }

  if (!data) {
    return {
      data: null,
      error: null,
      loading: false,
      refetch,
      state: FiniteState.Idle,
    };
  }

  return {
    data,
    error: null,
    loading: false,
    refetch,
    state: FiniteState.Ready,
  };
}

/** Generate query state, but add a post processing step that will inject calculated
 *  data into the returned data as well
 */
export function useToFiniteStateWithProcessing<T, R = unknown, U extends OperationVariables = OperationVariables>(
  result: QueryResult<T, U>,
  postReadyFunc: (data: T) => R,
): FiniteQueryState<T & R, U> {
  const { data, error, loading, refetch } = useToFiniteState(result);

  const memoizedData: (T & R) | null = React.useMemo(() => {
    if (!data) {
      return null;
    }
    return { ...data, ...postReadyFunc(data) };
  }, [data, postReadyFunc]);

  const refetchWithProcessing = async (variables?: Partial<U>): Promise<ApolloQueryResult<T & R>> => {
    // eslint-disable-next-line promise/avoid-new
    const newResult = await refetch(variables);
    return {
      ...newResult,
      data: { ...newResult.data, ...postReadyFunc(newResult.data) },
    };
  };

  if (loading) {
    return {
      data: null,
      error: null,
      loading,
      refetch: refetchWithProcessing,
      state: FiniteState.Loading,
    };
  }

  if (error) {
    return {
      data: null,
      error,
      loading: false,
      refetch: refetchWithProcessing,
      state: FiniteState.Error,
    };
  }

  if (!memoizedData) {
    return {
      data: null,
      error: null,
      loading: false,
      refetch: refetchWithProcessing,
      state: FiniteState.Idle,
    };
  }

  return {
    data: memoizedData,
    error: null,
    loading: false,
    refetch: refetchWithProcessing,
    state: FiniteState.Ready,
  };
}
