/* eslint-disable no-console */
import { boolean } from 'boolean';
import Router from 'next/router';

import ENV from '~/constants/ENV';

import { isStorybookEnv } from '.';

class Logger {
  name;

  constructor(name?: string) {
    this.name = name;
  }

  error(...args: unknown[]) {
    console.error(this.name, ...args);
  }

  log(...args: unknown[]) {
    console.log(this.name, ...args);
  }

  warn(...args: unknown[]) {
    console.warn(this.name, ...args);
  }

  trace(...args: unknown[]) {
    console.trace(this.name, ...args);
  }

  debug(...args: unknown[]) {
    try {
      const log = () => console.debug(this.name, ...args);

      // These all do the same thing, but we have to write it like this because
      // order matters. Tests and SSR don't like this function, but production does!
      if (isStorybookEnv) log();
      else if (ENV.NODE_ENV === 'development') log();
      else if (typeof window !== 'undefined' && boolean(Router.query.debug)) log();
    } catch (e: unknown) {
      // this is okay, sometimes this function is mad because `window` or `Router` doesn't exist.
    }
  }
}

export default Logger;
