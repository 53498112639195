import { useMemo } from 'react';

import { useCMSContext } from '~/client/context/provider';
import { getCMSUrl } from '~/client/utils/url';
import { CrafterImage } from '~/types/models/image';

export type Image = {
  src1x: string;
  src2x: string;
  value: number;
};
/**
 *
 * @param model Image CrafterImage
 * @returns a list of images with source and breakpoint value in descending order of breakpoint sizes
 */
export const useResponsiveImage = (model: CrafterImage): { imageSources: Image[] | null } => {
  const ctx = useCMSContext();
  const imageSources = useMemo(() => {
    // Hardcoded breakpoints because Encoura Classic theme has different values
    const staticBreakpoints = ctx.client.imageBreakpoints;

    const imgPathRegExp = /^\/static-assets\/content\/image((?:(?!\/[to]\/).)+)\/([^/.]+)\.(jpg|png)$/g;

    const imagesByBreakpoints: Image[] = staticBreakpoints.map(breakpointValue => {
      const overrideImage1xPath = model.images ? model.images[breakpointValue] : null;
      const overrideImage2xPath = model.images ? model.images[`${breakpointValue}_2x`] : null;

      const transformedImage1xPath = String(model.image_s).replace(
        imgPathRegExp,
        `/static-assets/content/image$1/t/$2-${breakpointValue}w.$3`,
      );
      const transformedImage2xPath = String(model.image_s).replace(
        imgPathRegExp,
        `/static-assets/content/image$1/t/$2-${breakpointValue}w-2x.$3`,
      );

      const src1xPath = overrideImage1xPath ?? transformedImage1xPath;
      const src2xPath = overrideImage2xPath ?? transformedImage2xPath;

      return {
        src1x: getCMSUrl(ctx, src1xPath),
        src2x: getCMSUrl(ctx, src2xPath),
        value: breakpointValue,
      };
    });

    return imagesByBreakpoints;
  }, [model, ctx]);

  return { imageSources };
};
