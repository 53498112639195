import { gql, useQuery } from '@apollo/client';
// eslint-disable-next-line regex/invalid-warn, @typescript-eslint/no-unused-vars
import * as Sentry from '@sentry/nextjs';

import { FiniteQueryState, useToFiniteStateWithProcessing } from '~/helpers/apollo/finiteQueryState';
import { getAccessToken } from '~/helpers/authTokens';
import useAuthReactiveTokens from '~/hooks/useAuthReactiveTokens';

import { GetMyProfile } from './__generatedGraphqlOperationTypes/GetMyProfile';

export interface IMyProfileData {
  completedOnboardingSurvey: boolean;
  isInternational?: boolean;
  isLoggedIn: boolean;
  recruitmentOptIn?: boolean | null;
}

export const LOGIN_GET_MY_PROFILE = gql`
  query LoginGetMyProfile {
    myProfile {
      acceptedTermsAndConditions {
        termsAndConditionsVersion
      }
      contact {
        homeAddress {
          countryCode
        }
      }
      encourageOnboardingComplete
      id
      identity {
        dateOfBirth
      }
      profileId
      encourageUser {
        visitorId
      }
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query GetMyProfile {
    myProfile {
      acceptedTermsAndConditions {
        termsAndConditionsVersion
      }
      majorSavedMajors {
        code
        isSaved
        title
      }
      collegeAdmissionPlans {
        collegeCampusPopulationSize
        collegeDriveHoursFromHome
        collegeGeographicSetting
        collegeSocialBehavior
        collegeTypes
        extraCurricularInterests
        mindsetCareAbout
        mindsetExpectationsForEducation
        mindsetExperiencesToLearnFrom
        preferredCollegeStates
        socialCulturalReligiousAffiliations
      }
      collegeRecruitmentInfo {
        collegeRecruitmentInd
      }
      contact {
        email
        homeAddress {
          city
          countryCode
          postalCode
          stateCode
          street1
          street2
        }
      }
      demographic {
        gender
        latinoBackground
        race
        religion
      }
      encourageOnboardingComplete
      favoriteOccupations {
        occupationId
        occupationDetails {
          onetId
          title
        }
      }
      highSchoolAcademicRecord {
        cumulativeGradePointAverage
      }
      highSchoolAdvancedPrograms {
        advancedPlacement
        cambridgeAICEDiploma
        careerAndTechnical
        collegeCreditCourses
        generalCollegePrep
        giftedAcceleratedPrograms
        honorsPrograms
        internationalBaccalaureate
        otherAdvancedPrograms
        otherGiftedAcceleratedPrograms
      }
      highSchoolCourses {
        grade
        plan
        title
      }
      highSchoolEnrollment {
        currentGrade
        enrolledAtHighSchoolId
        graduatingClassSize
        graduationYear
        programOfCourses
      }
      highSchoolExtracurricularActivities {
        campusCommunityServiceOrganizations
        debate
        dramaticsTheater
        fraternitySorority
        instrumentalMusic
        politicalOrganizations
        publications
        racialEthnicOrganizations
        radioTv
        religiousOrganizations
        studentGovernment
        varsityAthletics
        vocalMusic
      }
      id
      identity {
        firstName
        lastName
        dateOfBirth
      }
      militaryPlans {
        consideringMilitaryOptions
        militaryBranches
        militaryInterests
      }
      parentGuardianInfo {
        parentCollegeCompletionInd
      }
      profileId
      encourageUser {
        educatorConnections {
          educatorInfo {
            firstName
            highSchoolName
            inviteCode
            lastName
          }
        }
        displayName
        visitorId
      }
    }
  }
`;

export type IMyProfileResult = GetMyProfile & IMyProfileData;

export type IMyProfile = FiniteQueryState<IMyProfileResult>;

export const useMyProfile = (): IMyProfile => {
  const reactiveAccessToken = useAuthReactiveTokens(getAccessToken);
  const result = useQuery<GetMyProfile>(GET_MY_PROFILE, {
    onCompleted: data => {
      Sentry?.setUser({
        email: data.myProfile?.contact?.email || undefined,
        firstName: data.myProfile?.identity?.firstName,
        id: data.myProfile?.profileId,
        lastName: data.myProfile?.identity?.lastName,
      });
    },
    // Necessary to access College Profile page while not logged in
    skip: !reactiveAccessToken,
  });

  // This is so the user can log in faster, without querying all the extra stuff
  const minResult = useQuery<GetMyProfile>(LOGIN_GET_MY_PROFILE, {
    // Necessary to access College Profile page while not logged in
    skip: !reactiveAccessToken || !!result?.data?.myProfile,
  });

  const postReadyFunc = (data: GetMyProfile): IMyProfileData => {
    const completedOnboardingSurvey = !!data?.myProfile?.encourageOnboardingComplete;
    const countryCode = data?.myProfile?.contact?.homeAddress?.countryCode;
    const isInternational = countryCode ? countryCode !== 'US' : undefined;
    const isLoggedIn = !!data?.myProfile?.profileId;
    const recruitmentOptIn = data.myProfile?.collegeRecruitmentInfo?.collegeRecruitmentInd;

    return {
      completedOnboardingSurvey,
      isInternational,
      isLoggedIn,
      recruitmentOptIn,
    };
  };

  return useToFiniteStateWithProcessing(result?.data?.myProfile ? result : minResult, postReadyFunc);
};

export default useMyProfile;
