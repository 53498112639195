import { parseDescriptor, preParseSearchResults } from '@craftercms/content';
import { SearchResult } from '@craftercms/models';
import { ContentInstance } from '@craftercms/studio-ui/models';
import { isObject } from 'lodash';

import { CMSContext } from '~/types/cms-context';

type CrafterSearchResults = {
  results: ContentInstance[];
  totalCount: number;
};

/*
  When we load content via getItem, you end up with ONLY a body_html field that contains the actual html.
  So we manually fix the elasticsearch data by taking the body_html_raw content and setting it in to the body_html field
  then deleting the body_html_raw property (this step is critical).
*/
function removeHTMLRawProp(contentInstance: ContentInstance): ContentInstance {
  if (contentInstance.body_html && contentInstance.body_html_raw) {
    // eslint-disable-next-line no-param-reassign
    contentInstance.body_html = contentInstance.body_html_raw;
    // eslint-disable-next-line no-param-reassign
    delete contentInstance.body_html_raw;
  }

  return contentInstance;
}
const IMAGE_FIELD_REGEXP = /^image_([0-9].+)w(_2x)?_s$/;

function transformImageContent(key: string, value: string, contentInstance: ContentInstance): void {
  if (key.match(IMAGE_FIELD_REGEXP)) {
    const breakpoint = key.replace(IMAGE_FIELD_REGEXP, '$1$2');

    if (contentInstance.images && isObject(contentInstance.images)) {
      // eslint-disable-next-line no-param-reassign
      contentInstance.images = {
        ...contentInstance.images,
        [breakpoint]: value,
      };
    }

    // Deleting the old property to avoid recursion call into this conditional
    // eslint-disable-next-line no-param-reassign
    delete contentInstance[key];
  }
}

export const getCrafterRequestHeaders = (ctx: CMSContext, contentType?: string): HeadersInit => {
  const baseHeaders = {
    'Content-Type': contentType ?? 'application/json',
  };
  const token = ctx.getAccessToken();
  return token
    ? {
        ...baseHeaders,
        crafterSite: ctx.site,
        Authorization: `Bearer ${token}`,
      }
    : {
        ...baseHeaders,
        crafterSite: ctx.site,
      };
};

// Zod needs top-level property on each object to use as discriminator field in union
// Iterate through all content items and set contentTypeId on outer object to be used as discriminator field
// Code adapted from Crafter's preParseSearchResults
const modifyContentInstance = (contentInstance: ContentInstance): ContentInstance => {
  removeHTMLRawProp(contentInstance);
  Object.entries(contentInstance).forEach(([prop, value]) => {
    transformImageContent(prop, value as string, contentInstance);
    if (prop.endsWith('_o')) {
      const collection = value as { item: ContentInstance | ContentInstance[] };
      if (Array.isArray(collection)) {
        collection.forEach((item: ContentInstance) => {
          modifyContentInstance(item);
        });
      }
    }
  });
  return contentInstance;
};

export const fetchFromCrafter = async (
  ctx: CMSContext,
  init: RequestInit,
  path: string,
  querystring?: string,
): Promise<Response> => {
  let url = `${ctx.baseUrl}${path}?crafterSite=${ctx.site}`;
  if (querystring) {
    url += `&${querystring}`;
  }
  const reqHeaders = getCrafterRequestHeaders(ctx);
  const initForCrafter = { ...init };
  initForCrafter.headers = init.headers ? { ...init.headers, ...reqHeaders } : reqHeaders;
  return fetch(url, initForCrafter);
};

export const search = async (ctx: CMSContext, query: Record<string, unknown>): Promise<CrafterSearchResults> => {
  try {
    const resp = await fetchFromCrafter(
      ctx,
      {
        method: 'POST',
        body: JSON.stringify(query),
        mode: 'cors',
        credentials: 'same-origin',
      },
      '/api/1/site/search/search.json',
    );

    const rawResults = (await resp.json()) as { hits: SearchResult };
    const parsedResults: ContentInstance[] = rawResults.hits.hits.map(hit => {
      // eslint-disable-next-line no-underscore-dangle
      return modifyContentInstance(parseDescriptor(preParseSearchResults(hit._source)));
    });

    return { results: parsedResults, totalCount: rawResults.hits.total.value };
  } catch (err: unknown) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw err;
  }
};

/**
 * Fetch a single content instance from Crafter. If no instance is found for the path specified, an error is thrown.
 * @param ctx the CMS context
 * @param path a valid Crafter CMS path
 */
export const fetchContentInstance = async (ctx: CMSContext, path: string): Promise<ContentInstance> => {
  const query = {
    query: {
      term: {
        localId: path,
      },
    },
    sort: ['localId'],
    size: 1,
  };

  const { results } = await search(ctx, query);
  if (results && results[0] && results[0].craftercms.path === path) {
    return results[0];
  }
  throw new Error(`Not found: ${path}`);
};

/**
 * @deprecated Use fetchContentInstance instead (drop-in replacement)
 * @see fetchContentInstance (drop-in replacement)
 */
export const readContentInstanceUsingSearch = async (ctx: CMSContext, path: string): Promise<ContentInstance> => {
  return fetchContentInstance(ctx, path);
};

/**
 * Fetch multiple content instances from Crafter.  If any of the paths specified are not found, an error is thrown.
 * Notably, if the requesting user does not have access to any of the paths, it will be missing and therefore an
 * error will also be thrown.
 * @param ctx the CMS context
 * @param paths array of valid Crafter CMS paths
 */
export const fetchContentInstances = async (ctx: CMSContext, paths: string[]): Promise<ContentInstance[]> => {
  const resp = await fetchFromCrafter(
    ctx,
    {
      method: 'POST',
      body: JSON.stringify(paths),
      mode: 'cors',
      credentials: 'same-origin',
    },
    '/api/1/services/crt/fetch-content-instances.json',
  );

  const rawResults = (await resp.json()) as { hits: SearchResult };
  const parsedResults: ContentInstance[] = rawResults.hits.hits.map(hit => {
    // eslint-disable-next-line no-underscore-dangle
    return modifyContentInstance(parseDescriptor(preParseSearchResults(hit._source)));
  });

  if (paths.length !== parsedResults.length) {
    const missingPaths = paths.filter(path => !parsedResults.find(result => result.craftercms.path === path));
    throw new Error(`Not all paths were found. Missing paths: ${missingPaths.join()}`);
  }

  return parsedResults;
};
