export const CONTENT_TYPES = {
  image: '/component/image',
  document: '/component/document',
  flexcontent: '/component/flexcontent',
  textblock: '/component/textblock',
  simplelist: '/component/simplelist',
  specialnote: '/component/specialnote',
  youtubevideo: '/component/youtubevideo',
  wistiavideo: '/component/wistiavideo',
  video: '/component/video',
  adapter1up: '/component/1-up',
  adapter1111: '/component/1-1-1-1',
  adapter121: '/component/1-2-1',
  adapter13: '/component/1-3',
  adapter31: '/component/3-1',
  adapter22: '/component/2-2',
} as const;
