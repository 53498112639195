import * as Sentry from '@sentry/nextjs';
import { isError } from 'lodash';

const sentryApolloClientError = (err: unknown) => {
  Sentry?.captureException(err, {
    extra: {
      error: isError(err) && err.message,
    },
    tags: {
      feature: 'ApolloClient',
    },
  });
};

export default sentryApolloClientError;
