import { useReactiveVar } from '@apollo/client';
import Alert from '@mui/material/Alert';
import { CMSClientConfig, CMSProvider } from 'encoura-cms-toolkit/client/context';
import React from 'react';

import { debugCrafterAuthoringUrlVar, debugCrafterDeliveryUrlVar } from '~/buses/debugMenu';
import ENV from '~/constants/ENV';

const CmsProviderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const debugCrafterAuthoringUr = useReactiveVar(debugCrafterAuthoringUrlVar);
  const debugCrafterDeliveryUrl = useReactiveVar(debugCrafterDeliveryUrlVar);

  const cmsConfig: CMSClientConfig = React.useMemo(
    () => ({
      crafterAuthoringBaseUrl: debugCrafterAuthoringUr || ENV.CRAFTER_AUTHORIZING_BASE_URL || '',
      crafterDeliveryBaseUrl: debugCrafterDeliveryUrl || ENV.CRAFTER_DELIVERY_BASE_URL || '',
      crafterSiteName: ENV.CRAFTER_SITE_NAME || '',
    }),
    [debugCrafterAuthoringUr, debugCrafterDeliveryUrl],
  );

  if (
    process.env.NEXT_PUBLIC_SENTRY_ENV !== 'production' &&
    (!cmsConfig.crafterAuthoringBaseUrl || !cmsConfig.crafterDeliveryBaseUrl || !cmsConfig.crafterSiteName)
  ) {
    return (
      <Alert severity="error">
        Missing environment variables in your .env file.{' '}
        <ul>
          {!cmsConfig.crafterAuthoringBaseUrl && <li>CRAFTER_AUTHORIZING_BASE_URL</li>}
          {!cmsConfig.crafterDeliveryBaseUrl && <li>CRAFTER_DELIVERY_BASE_URL</li>}
          {!cmsConfig.crafterSiteName && <li>CRAFTER_SITE_NAME</li>}
        </ul>
        Use variables from .env.example
      </Alert>
    );
  }
  // @ts-expect-error Needs to be fixed by toolkit
  return <CMSProvider config={cmsConfig}>{children}</CMSProvider>;
};

export default CmsProviderComponent;
