'use client';

import { RenderField, RenderRepeat } from '@craftercms/experience-builder/react';
import { ContentInstance } from '@craftercms/studio-ui/models';
import { SxProps } from '@mui/material';

import { CrafterSimpleList } from '~/types/models/simple-list';

import { StyledList } from './styles';

export type SimpleListProps = {
  model: CrafterSimpleList;
  sx?: SxProps;
};

export const SimpleList = ({ model, sx }: SimpleListProps): React.ReactNode => {
  if (!model) return null;

  return (
    <RenderRepeat
      component={StyledList}
      componentProps={{ sx }}
      fieldId="list_o"
      itemComponent="li"
      model={model}
      renderItem={(_item: ContentInstance, index): React.ReactElement => (
        // That way the component knows that the field we are rendering is 'text_s' from 'list_o'
        // We also need to let the component know the index of the field inside the rep group being rendered
        <RenderField fieldId="list_o.text_s" index={index} model={model} />
      )}
    />
  );
};
