import { ParsedUrlQueryInput } from 'querystring';

import { isUndefined, omitBy } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

import ENV from '~/constants/ENV';
import NEXT from '~/constants/NEXT';
import { isStorybookEnv } from '~/helpers';
import { asError } from '~/helpers/errors';
import Logger from '~/helpers/logger';
import { sentryRoutingError } from '~/helpers/SentryErrors';

interface IProps {
  external?: boolean;
  isReplace?: boolean;
  params?: ParsedUrlQueryInput;
  to: string;
}
const logger = new Logger('Redirect');

const Redirect: React.FC<IProps> = ({ external = false, isReplace = false, params, to }: IProps): React.ReactElement<unknown> => {
  const router = useRouter();

  if (isStorybookEnv) return <>Redirect to: {to}</>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect((): void => {
    if (NEXT.IS_CLIENT) {
      const query = omitBy(params, isUndefined);
      logger.debug('Redirecting', {
        // Please don't ACTUALLY fire me for using this. It's only here so we can see WHO is redirecting,
        // as that can be difficult to trace when we're seeing redirects happen for no apparent reason.

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        calledBy:
          ENV.NODE_ENV === 'development'
            ? // @ts-expect-error We're digging into mystical react voodoo, so of course it's not typed
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, no-underscore-dangle
              (React.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.ReactDebugCurrentFrame.getCurrentStack() as string | undefined)
                ?.split('\n')
                .filter((x: string | undefined | null) => !!x)
            : undefined,
        path: router?.asPath,
        query,
        to,
      });
      if (external) {
        router[isReplace ? 'replace' : 'push'](to, {
          query,
        })
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((unkErr: unknown) => {
            const error = asError(unkErr);
            sentryRoutingError({ error: unkErr, message: 'Error while redirecting', query, to });

            logger.error('Error while redirecting', error);
          });
      } else {
        router[isReplace ? 'replace' : 'push']({
          pathname: to,
          query,
        })
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((unkErr: unknown) => {
            const error = asError(unkErr);
            sentryRoutingError({ error: unkErr, message: 'Error while redirecting', query, to });
            logger.error('Error while redirecting', error);
          });
      }
    }
  }, [external, params, router, to, isReplace]);

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `<!-- Redirecting to ${to} ${params ? `with params ${JSON.stringify(params)} ` : ''}External? ${String(external ?? false)} -->`,
      }}
    />
  );
};

export default Redirect;
