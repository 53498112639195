'use client';

import { RenderField } from '@craftercms/experience-builder/react';
import { Box, SxProps, Typography } from '@mui/material';

import { CrafterWistiaVideo } from '~/types/models/wistia-video';

import { StyledWistiaVideoContainer, StyledWistiaVideoWrapper } from './styles';

export type WistiaVideoProps = {
  model: CrafterWistiaVideo;
  sx?: SxProps;
};

const getVideoId = (url: string): string => {
  const idPattern = /\/([^/]+)$/;

  const match = url.match(idPattern);
  const id = match ? match[1] : null;
  return id ?? '';
};

export const WistiaVideo = ({ model, sx }: WistiaVideoProps): React.ReactElement => {
  const videoId = getVideoId(model.url_t);
  const description = model.description_t ?? undefined;

  return (
    <Box sx={sx}>
      <StyledWistiaVideoContainer>
        <StyledWistiaVideoWrapper>
          <iframe
            allowFullScreen
            className="wistia_embed"
            height="100%"
            name="wistia_embed"
            src={`https://fast.wistia.net/embed/iframe/${videoId}?videoFoam=true`}
            title={description}
            width="100%"
          />
        </StyledWistiaVideoWrapper>
      </StyledWistiaVideoContainer>
      {description && (
        <RenderField
          component={Typography}
          componentProps={{ variant: 'subtitle1', sx: { padding: 1, textAlign: 'center' } }}
          fieldId="description_t"
          model={model}
        />
      )}
    </Box>
  );
};
