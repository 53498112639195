'use client';

import AttachFile from '@mui/icons-material/AttachFile';
import { Stack, Link } from '@mui/material';
import React from 'react';

import { DocumentProps } from './Document';

type Props = {
  filePath: string;
  fileName: string;
} & DocumentProps;

export const DocumentView = ({ model, sx, filePath, fileName }: Props): React.ReactNode => {
  return (
    <Stack direction="row" sx={sx}>
      <AttachFile fontSize="small" />
      <Link href={filePath} target="_blank" title={model.title_t}>
        {fileName}
      </Link>
    </Stack>
  );
};
