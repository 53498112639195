/* eslint-disable import/prefer-default-export */

import { isApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { isError, isObject } from 'lodash';

import { IAlert } from '~/types/dls';

import GenericError from './genericError';
import UnknownError from './unknownError';

export type OnErrorHandler = (
  error: Error,
  message?: string,
  onIgnoreError?: () => void | Promise<void>,
) => void | Promise<void>;

/**
 * Returns an appropriate instance of Error for any unknown caught error.
 * Should be called in every "catch" clause to convert the unknown caught value type.
 */
export const asError = (unkErr: unknown): Error => {
  try {
    if (isError(unkErr)) {
      return unkErr;
    }
    if (isObject(unkErr)) {
      return new GenericError(unkErr as Record<string, unknown>);
    }
    return new UnknownError(unkErr);
  } catch (_: unknown) {
    // Should never happen, but just in case everything fails, we still need
    // to continue the error handling from the caller.
    /* istanbul ignore next */
    Sentry?.captureException(unkErr);
    return unkErr as Error;
  }
};

/**
 * Returns an user-friendly error message, for the purpose of being displayed in the UI.
 */
export function getFriendlyErrorMessage(err: Error): string {
  if (isApolloError(err) && err.networkError) {
    // @ts-expect-error: ApolloError may have "result" property
    const serverMessage = 'result' in err.networkError ? (err.networkError.result?.message as string) : undefined;
    return serverMessage ?? err.networkError.message;
  }
  if (err instanceof GenericError) {
    const { message, status } = err.errorData;
    if (message) return `Oops, a problem ocurred: ${message ?? ''}`;
    if (status) return `Oops, a problem ocurred! Error status: ${status ? String(status) : ''}`;
    return `Oops, a problem ocurred!`;
  }
  return err.message;
}

/**
 * Converts an Error object to a snackbar alert.
 * For usage with "AlertContext.addAlert" action function from @actinc/dls.
 */
export function toErrorAlert(err: Error): IAlert {
  return {
    message: getFriendlyErrorMessage(err),
    options: {
      variant: 'error',
    },
  };
}
