import { Loading } from '@actinc/dls/components/Loading';
import Box from '@mui/material/Box';
import React from 'react';

const PageLoading: React.FC = () => {
  return (
    <Box sx={{ height: '100vh', placeItems: 'center' }}>
      <Loading />
    </Box>
  );
};

export default PageLoading;
