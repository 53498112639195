/* eslint-disable import/prefer-default-export */
import { makeVar } from '@apollo/client';

export const showTypographyHighlightVar = makeVar(false);
export const showHighlightDivsVar = makeVar(false);
export const showSafeAreaVar = makeVar(false);
export const reactQueryDevtoolsOpenVar = makeVar(false);

export const debugCrafterAuthoringUrlVar = makeVar('');
export const debugCrafterDeliveryUrlVar = makeVar('');
