import { useRouter } from 'next/router';
import React from 'react';

import { useLtiProviderQuery } from '~/__generated__/graphql';
import { setLtiToken } from '~/helpers/authTokens';

import PageLoading from '../PageLoading';

const LtiProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();

  const { session_id: sessionId, ...otherQueryParams } = router.query;

  useLtiProviderQuery({
    onCompleted: async (data): Promise<void> => {
      const ltiAccessToken = data?.encouraLtiFormToken?.accessToken || '';

      if (ltiAccessToken) {
        setLtiToken(String(ltiAccessToken));

        await router.replace({
          pathname: window.location.pathname,
          query: otherQueryParams,
        });
      }
    },
    skip: !sessionId,
    variables: {
      sessionId: String(sessionId),
    },
  });

  if (sessionId) {
    return <PageLoading />;
  }

  return <>{children}</>;
};

export default LtiProvider;
