import { isApolloError, ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { isError } from 'lodash';

const sentryGraphqlRequestError = ({
  error,
  variables,
  level = 'error',
}: {
  error: unknown;
  variables: unknown;
  level?: 'error' | 'warning';
}) => {
  if (isApolloError(error as Error)) {
    if ((error as ApolloError).message === 'Unauthorized') return; // TODO check this line

    Sentry?.captureException(error, {
      extra: {
        error: isError(error) && error.message,
        variables: JSON.stringify(variables),
      },
      level,
      tags: { feature: 'GraphqlRequest' },
    });
  } else {
    Sentry?.captureException(error, {
      extra: {
        error: isError(error) && error.message,
      },
      level: 'error',
    });
  }
};

export default sentryGraphqlRequestError;
