import * as Sentry from '@sentry/nextjs';
import { IBridgeMessageTypes } from 'encourage-ecosystem-lib';
import { isError } from 'lodash';

const sentryReactNativeBridgeError = ({
  error,
  bridgeMessageType,
  bridgeData,
}: {
  error: unknown;
  bridgeMessageType: IBridgeMessageTypes;
  bridgeData: unknown;
}) => {
  Sentry?.captureException(error, {
    extra: {
      bridgeData: JSON.stringify(bridgeData),
      bridgeMessageType,
      error: isError(error) && error.message,
    },
    tags: { feature: 'ReactNativeBridge' },
  });
};

export default sentryReactNativeBridgeError;
