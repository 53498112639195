import isChromatic from 'chromatic';
import Lottie, { LottieComponentProps } from 'lottie-react';
import React from 'react';

export default function makeAnimation(data: LottieComponentProps['animationData']) {
  return function Animation(props: Omit<LottieComponentProps, 'animationData'>): React.ReactElement {
    const chromatic = isChromatic();
    return <Lottie {...props} animationData={data} {...(chromatic && { autoPlay: false })} />;
  };
}
