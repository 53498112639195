const journeyYears: readonly number[] = [12, 11, 10, 9, 8, 7, 6];

const getIndex = (graduationYear: number | null | undefined, currentDate?: Date) => {
  if (!graduationYear) {
    return undefined;
  }
  const currDate = currentDate ? new Date(currentDate) : new Date();
  let currGradYear = currDate.getFullYear();

  if (currDate.getUTCMonth() + 1 >= 7) {
    currGradYear += 1;
  }

  return graduationYear - currGradYear;
};

export const getStudentGrade = (graduationYear: number | null | undefined, currentDate?: Date) => {
  const index = getIndex(graduationYear, currentDate);

  if (index === undefined) {
    return undefined;
  }

  if (index < 0) {
    return 12;
  }
  return index < journeyYears.length ? journeyYears[index] : undefined;
};

// eslint-disable-next-line import/prefer-default-export
export const getStudentGradeLevel = (graduationYear: number | null | undefined) => {
  const index = getIndex(graduationYear);

  if (index === undefined) {
    return undefined;
  }

  let finalGradeLevel: string | undefined = '';
  if (index < 0) {
    finalGradeLevel = 'High School Graduate';
  } else if (index < journeyYears.length) {
    finalGradeLevel = `${journeyYears[index]}th`;
  } else {
    finalGradeLevel = undefined;
  }
  return finalGradeLevel;
};
