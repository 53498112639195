import { noop } from 'lodash';
import { useRouter } from 'next/router';
import React from 'react';

import ENV from '~/constants/ENV';
import FEATURE_FLAGS from '~/constants/FEATURE_FLAGS';
import NAMES from '~/constants/NAMES';
import { FeatureFlagContext } from '~/context/FeatureFlagsHandler/FeatureFlags';
import { isStorybookEnv } from '~/helpers';
import Logger from '~/helpers/logger';

const logger = new Logger('WithAppRefresher');

const WithAppRefresher: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { featureFlags } = React.useContext(FeatureFlagContext);
  const router = useRouter();

  // Transforming into ms the default/debug inverval value from minutes.
  const INTERVAL_MS = (featureFlags?.[FEATURE_FLAGS.APP_REFRESHER_INTERVAL] || 5) * 60 * 1000;

  const fetchAndStoreAppVersion = async () => {
    const res = await fetch('/static/app-version', { cache: 'no-cache' });
    const appVersion = await res.text();
    localStorage.setItem(NAMES.APP_VERSION_STORAGE_KEY, appVersion);
  };

  const onRouteChangeStart = (nextPath: string) => {
    const fetchedAppVersion = localStorage.getItem(NAMES.APP_VERSION_STORAGE_KEY);
    const envAppVersion = ENV.APP_VERSION;
    logger.debug(`RouteChangeStart currentVersion=${fetchedAppVersion ?? ''} and envVersion=${envAppVersion ?? ''}`);

    if (!fetchedAppVersion || fetchedAppVersion === envAppVersion) return;
    logger.debug(`RouteChangeStart client version and fetched versions do not match. Redirecting to ${nextPath}`);

    localStorage.removeItem(NAMES.APP_VERSION_STORAGE_KEY);
    window.location.href = nextPath;

    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw `New App Version detected`;
  };

  React.useEffect(() => {
    if (isStorybookEnv) return noop;

    router.events.on('routeChangeStart', onRouteChangeStart);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchAndStoreAppVersion();
    const intervalId = setInterval(fetchAndStoreAppVersion, INTERVAL_MS);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      clearInterval(intervalId);
    };
  }, [INTERVAL_MS]);

  return <>{children}</>;
};

export default WithAppRefresher;
