/* eslint-disable camelcase */
import { GetMyProfile_myProfile } from '~/hooks/useMyProfile/__generatedGraphqlOperationTypes/GetMyProfile';
import { Gender, Race, StateCode, YesNoPreferNotRespond, YesNoPreferNotRespondNotSure } from '~/types/globalTypes';

export const getCurrentSegmentStates = (profile: GetMyProfile_myProfile | null | undefined) => {
  if (profile !== null && !!profile?.collegeAdmissionPlans?.preferredCollegeStates) {
    if (!profile.collegeAdmissionPlans.preferredCollegeStates.includes(StateCode.NOT_SURE)) {
      return profile.collegeAdmissionPlans.preferredCollegeStates;
    }
  }
  return ['No Answer'];
};

const getCurrentSegmentLatinoBackground = (profile: GetMyProfile_myProfile | null | undefined) => {
  switch (profile?.demographic?.latinoBackground) {
    case YesNoPreferNotRespond.YES:
      return 'Yes';
    case YesNoPreferNotRespond.NO:
      return 'No';
    case YesNoPreferNotRespond.PREFER_NOT_TO_RESPOND:
      return 'Prefer Not To Respond';
    default:
      return 'unknown';
  }
};

export const getCurrentRecruitmentChoice = (profile: GetMyProfile_myProfile | null | undefined) => {
  switch (profile?.collegeRecruitmentInfo?.collegeRecruitmentInd) {
    case true:
      return 'Yes - Opted-In';
    case false:
      return 'No - Opted-Out';
    default:
      return 'unknown';
  }
};

export const getEducatorConnections = (profile: GetMyProfile_myProfile | null | undefined) => {
  if (profile?.encourageUser.educatorConnections?.length) {
    return profile?.encourageUser.educatorConnections?.length > 0 ? 'Yes - Connected' : 'No - Not Connected';
  }
  return 'No - Not Connected';
};

export const getCurrentSegmentEthnicity = (profile: GetMyProfile_myProfile | null | undefined) => {
  const ethnicityList = [];

  const hispanicBackground = getCurrentSegmentLatinoBackground(profile);
  if (hispanicBackground === 'Yes') {
    ethnicityList.push('Latino');
  }

  if (profile?.demographic?.race != null) {
    profile.demographic?.race?.forEach(element => {
      switch (element) {
        case Race.AMERICAN_INDIAN_ALASKA_NATIVE:
          return 'American Indian or Alaskan Native';
        case Race.ASIAN:
          return 'Asian';
        case Race.BLACK_AFRICAN_AMERICAN:
          return 'Black or African American';
        case Race.NATIVE_HAWAIIAN_OTHER_PACIFIC_ISLANDER:
          return 'Native Hawaiian or Other Pacific Islander';
        case Race.WHITE:
          return 'White';
        case Race.PREFER_NOT_TO_RESPOND:
          return 'Prefer not to answer';
        default:
          return 'unknown';
      }
    });
  }
  return ethnicityList;
};

export const getCurrentSegmentFirstGenerationCollegeStudent = (profile: GetMyProfile_myProfile | null | undefined) => {
  switch (profile?.parentGuardianInfo?.parentCollegeCompletionInd) {
    case YesNoPreferNotRespondNotSure.YES:
      return 'Not First Generation';
    case YesNoPreferNotRespondNotSure.NO:
      return 'First Generation';
    case YesNoPreferNotRespondNotSure.NOT_SURE:
      return 'Not sure';
    case YesNoPreferNotRespondNotSure.PREFER_NOT_TO_RESPOND:
      return 'Prefer Not To Respond';
    default:
      return 'unknown';
  }
};

export const getCurrentSegmentGender = (profile: GetMyProfile_myProfile | null | undefined) => {
  switch (profile?.demographic?.gender) {
    case Gender.ANOTHER_GENDER:
      return 'Another Gender';
    case Gender.FEMALE:
      return 'Female';
    case Gender.MALE:
      return 'Male';
    case Gender.PREFER_NOT_TO_RESPOND:
      return 'Prefer not to respond';
    default:
      return 'unknown';
  }
};

export const getCurrentSegmentUserState = (profile: GetMyProfile_myProfile | null | undefined) => {
  return profile?.contact?.homeAddress?.stateCode ?? 'unknown';
};
