/**
 * Used as a fallback for any thrown value that is not actually an Error object,
 * such as strings, numbers, et cetera. The caught value is converted to string.
 *
 * Do not use this error directly, it is only meant for catching exceptional cases.
 */
export default class UnknownError extends Error {
  public readonly name = 'UnknownError';

  constructor(value?: unknown) {
    super(`Unknown error "${String(value) ?? ''}"`);
  }
}
