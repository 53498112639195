import Paper from '@mui/material/Paper';

import { styled } from '~/helpers/material/styled';

export const StyledContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '12px',
  boxShadow: '0px 0px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));
