import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import React from 'react';

import { useReactNativeObj } from '~/hooks/useReactNativeObj';

interface IPageTransitionLayoutProps {
  children: React.ReactElement<unknown>;
}

const PageTransitionLayout: React.FC<IPageTransitionLayoutProps> = ({ children }): React.ReactElement<unknown> => {
  const router = useRouter();
  const reactNativeObj = useReactNativeObj();
  const isNativeApp = !!reactNativeObj;

  if (isNativeApp) {
    return (
      <AnimatePresence mode="popLayout">
        <motion.div
          animate="animate"
          exit="exit"
          initial="initial"
          key={router.pathname}
          transition={{
            duration: 0.25,
            type: 'tween',
          }}
          variants={{
            animate: {
              opacity: 1,
            },
            exit: {
              opacity: 0,
            },
            initial: {
              opacity: 0,
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    );
  }

  return children;
};

export default PageTransitionLayout;
