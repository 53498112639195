'use client';

import { RenderField } from '@craftercms/experience-builder/react';
import { SxProps } from '@mui/material';

import { useCMSContext } from '~/client/context/provider';
import { CrafterTextBlock } from '~/types/models/text-block';

import { StyledRichText } from './styles';

export type TextBlockProps = {
  model: CrafterTextBlock;
  sx?: SxProps;
};

export const TextBlock = ({ model, sx }: TextBlockProps): React.ReactNode => {
  const { xb } = useCMSContext();

  if (!model) return null;

  return (
    <RenderField
      component={StyledRichText}
      componentProps={{ isAuthoring: xb.isAuthoring, component: 'div', sx }}
      fieldId="body_html"
      model={model}
      render={(data: string): { __html: string } => ({ __html: data })}
      renderTarget="dangerouslySetInnerHTML"
    />
  );
};
