/**
 * Used as a fallback for any external error object which is not an instance of the Error class.
 *
 * Do not use this error directly. Instead, consider using a meaningful error type.
 */
export default class GenericError extends Error {
  public readonly name = 'GenericError';

  constructor(public readonly errorData: Record<string, unknown> & { message?: string }) {
    const message = errorData.message ? errorData.message : JSON.stringify(errorData);
    super(message);
  }
}
