/* eslint-disable */

import logger from '../logger';
import * as Sentry from '@sentry/nextjs';

/* this is a 3rd party code snippet for initializing the Pendo lib. Provided by https://app.pendo.io/ */
export function loadPendo(apiKey) {
  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');

  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function after users are authenticated in your app and your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
}

export function initPendo(visitorId, visitorData = {}) {
  try {
    pendo[pendo.visitorId ? 'identify' : 'initialize']({
      visitor: {
        id: visitorId, // Required if user is logged in, default creates anonymous ID
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        ...visitorData,
      },
    });
  } catch (e) {
    logger.debug('Failed to initialize pendo');
    Sentry && Sentry.captureException(unkErr, { extra: { message: 'Failed to initialize pendo' } });
  }
}
