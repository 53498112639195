import { isObject } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function censorPasswords(obj: any): any {
  // Check if the object is an array
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map(censorPasswords);
  }
  // Check if the object is not of type 'object' or is null
  if (!isObject(obj) || obj === null) {
    return obj;
  }
  // Create a new object to hold the censored values
  const censoredObj: { [key: string]: unknown } = {};
  // Iterate over keys in the object
  Object.keys(obj).forEach(key => {
    const value = (obj as { [key: string]: unknown })[key];
    // If the key includes 'password', censor the value
    if (['password', 'token'].some(s => key.toLocaleLowerCase().includes(s))) {
      censoredObj[key] = '****';
    } else {
      // Otherwise, recursively call the function on the value
      censoredObj[key] = censorPasswords(value);
    }
  });

  return censoredObj;
}

export default censorPasswords;
