import { useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ThemeProvider from '@mui/system/ThemeProvider';
import deepmerge from 'deepmerge';
import React, { ReactNode } from 'react';

import { showTypographyHighlightVar, showHighlightDivsVar } from '~/buses/debugMenu';

interface IDebugThemeProps {
  children: ReactNode;
}

const DebugThemeProvider = ({ children }: IDebugThemeProps) => {
  const showTypographyHighlight = useReactiveVar(showTypographyHighlightVar);
  const showHighlightDivs = useReactiveVar(showHighlightDivsVar);

  if (!showTypographyHighlight && !showHighlightDivs) return <>{children}</>;
  const themeHighlightDivs = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          div: {
            background: 'rgba(0,100,255,0.05) !important',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0em 0em 0em .1em inset !important',
          },
          span: {
            background: 'rgba(255,0,0,0.2) !important',
            boxShadow: 'rgba(255, 0, 0, 0.3) 0em 0em 0em .1em inset !important',
          },
        },
      },
    },
  };
  const typographyHighlight = {
    components: {
      MuiTypography: {
        styleOverrides: {
          body1: {
            background: '#f17373 !important',
          },
          body2: {
            background: '#88f !important',
          },
          button: {
            background: 'olive !important',
          },
          caption: {
            background: 'aqua !important',
          },
          h1: {
            background: 'pink !important',
          },
          h2: {
            background: 'limegreen !important',
          },
          h3: {
            background: 'silver !important',
          },
          h4: {
            background: 'yellow !important',
          },
          h5: {
            background: 'orange !important',
          },
          h6: {
            background: 'Fuchsia !important',
          },
          subtitle1: {
            background: 'lime !important',
          },
          subtitle2: {
            background: 'teal !important',
          },
        },
      },
    },
  };

  let combinedTheme = {};
  if (showTypographyHighlight) combinedTheme = deepmerge(combinedTheme, typographyHighlight);
  if (showHighlightDivs) combinedTheme = deepmerge(combinedTheme, themeHighlightDivs);

  return (
    <ThemeProvider theme={outerTheme => deepmerge(outerTheme, combinedTheme)}>
      {children}
      {showTypographyHighlight && (
        <Box sx={{ bottom: 0, left: 0, position: 'fixed', zIndex: 999999 }}>
          {['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'subtitle1', 'subtitle2', 'button', 'caption'].map(variant => (
            // @ts-expect-error We know this variant is correct
            <Typography component="div" key={variant} sx={theme => ({ ...theme.typography.body1, px: 1 })} variant={variant}>
              {variant}
            </Typography>
          ))}
        </Box>
      )}
    </ThemeProvider>
  );
};

export default DebugThemeProvider;
