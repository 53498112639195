import colors from '@actinc/dls/styles/themeEncourage/colors';
import Box, { BoxProps } from '@mui/material/Box';

import { styled } from '~/helpers/material/styled';

// eslint-disable-next-line import/prefer-default-export
export const StyledBox = styled(Box)<BoxProps>(() => ({
  backgroundColor: colors.navy[100],
  bottom: 0,
  position: 'fixed',
  width: '100vw',
  /** We want this value to be ridiculously high, so it sits on top of everything.
   * This way we're forced to manually hide it, just as we would have to do with React Native
   */
  zIndex: 2147483647 - 1, // Max value for zIndex - 1
}));
