/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */

import { NAMES } from 'encourage-ecosystem-lib/constants';
import { IReactNativeObj, IReactNativeWebView } from 'encourage-ecosystem-lib/types';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    [NAMES.REACT_NATIVE_OBJ]?: IReactNativeObj;
    [NAMES.REACT_NATIVE_WEB_VIEW]?: IReactNativeWebView;
    pendo: {
      initialize: (options: Record<string, unknown>) => void;
      identify: (id: string, options: Record<string, unknown>) => void;
      pageLoad: (options: Record<string, unknown>) => void;
      track: (event: string, options: Record<string, unknown>) => void;
      updateOptions: (options: Record<string, unknown>) => void;
    };
    smartbanner: {
      publish: () => unknown;
    };
    openExternalLink: (href: string) => void;
    e4s_sendDebugHeader: boolean;
  }
}

export const isStorybookEnv = process.env.IS_STORYBOOK === 'true';
