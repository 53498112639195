import React from 'react';

import { ReactNativeFeaturesContext } from '~/context/ReactNativeFeatures';
import { isExternalLink } from '~/helpers/links';
import { useOpenUrlWithBridge } from '~/hooks/useOpenUrlWithBridge';

const WithGlobalUtilities: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    state: { isReactNative },
  } = React.useContext(ReactNativeFeaturesContext);
  const sendUrlToBridge = useOpenUrlWithBridge();

  React.useEffect(() => {
    window.openExternalLink = async (href: string): Promise<void> => {
      const isExternal = isExternalLink(href);
      if (isReactNative && isExternal) {
        await sendUrlToBridge(href);
      } else {
        window.open(href, isExternal ? '_blank' : undefined);
      }
    };
  }, [isReactNative, sendUrlToBridge]);
  return <>{children}</>;
};

export default WithGlobalUtilities;
