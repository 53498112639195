import { notImplemented } from '@actinc/dls/helpers';
import moment, { Moment } from 'moment-timezone';
import React from 'react';

import FEATURE_FLAGS from '~/constants/FEATURE_FLAGS';
import { FeatureFlagContext } from '~/context/FeatureFlagsHandler/FeatureFlags';
import ScheduledMaintenance from '~/views/Signin/Page/ScheduledMaintenance';

const EXPECTED_FF_DATE_FORMAT = 'YYYY-MM-DD hh:mmA';
const DATE_LABEL_FORMAT = 'dddd, MMM D [at] hA [CT]';

export interface IMaintenanceContext {
  endDate: Moment | false | '';
  showMaintenance: boolean;
  startDate: Moment | false | '';
  labelFormatting?: string;
  maintenanceBannerHeight: number;
  setMaintenanceBannerHeight: React.Dispatch<React.SetStateAction<number>>;
}

enum MaintenanceSeverity {
  P1 = 'P1',
  P2 = 'P2',
}

export const MaintenanceContext = React.createContext<IMaintenanceContext>({
  endDate: false,
  labelFormatting: DATE_LABEL_FORMAT,
  maintenanceBannerHeight: 0,
  setMaintenanceBannerHeight: notImplemented,
  showMaintenance: false,
  startDate: false,
});

const MaintenanceProvider = MaintenanceContext.Provider;

export const getMaintenanceState = (
  maintenanceBannerHeight: number,
  setMaintenanceBannerHeight: React.Dispatch<React.SetStateAction<number>>,
  {
    endDateFlag,
    severityString,
    startDateFlag,
  }: {
    endDateFlag: string | false;
    severityString: string | false;
    startDateFlag: string | false;
  },
): IMaintenanceContext => {
  const startDate = startDateFlag && moment.tz(startDateFlag.toString(), EXPECTED_FF_DATE_FORMAT, 'America/Chicago');
  const endDate = endDateFlag && moment.tz(endDateFlag.toString(), EXPECTED_FF_DATE_FORMAT, 'America/Chicago');

  const localTime = moment.tz('America/Chicago');
  const isWithinWindow = startDate && endDate ? localTime.isBetween(startDate, endDate, 'seconds') : false;

  const showMaintenance = isWithinWindow && severityString === MaintenanceSeverity.P2;

  return {
    endDate,
    labelFormatting: DATE_LABEL_FORMAT,
    maintenanceBannerHeight,
    setMaintenanceBannerHeight,
    showMaintenance,
    startDate,
  };
};

export const MaintenanceProviderComponent: React.FC<React.PropsWithChildren<{ forceSeverity?: 'P1' | 'P2' }>> = ({
  children,
  forceSeverity /** For debugging */,
}) => {
  const { featureFlags } = React.useContext(FeatureFlagContext);
  const [maintenanceBannerHeight, setMaintenanceBannerHeight] = React.useState<number>(0);

  const severityString = forceSeverity || featureFlags?.[FEATURE_FLAGS.MAINTENANCE_SEVERITY] || false;

  const maintenanceState = getMaintenanceState(maintenanceBannerHeight, setMaintenanceBannerHeight, {
    endDateFlag: forceSeverity ? `${new Date().getFullYear() + 5}` : featureFlags?.[FEATURE_FLAGS.MAINTENANCE_END_DATE] || false,
    severityString,
    startDateFlag: forceSeverity ? `${new Date().getFullYear() - 5}` : featureFlags?.[FEATURE_FLAGS.MAINTENANCE_START_DATE] || false,
  });

  const localTime = moment.tz('America/Chicago');
  const isWithinWindow =
    maintenanceState.startDate && maintenanceState.endDate ? localTime.isBetween(maintenanceState.startDate, maintenanceState.endDate, 'seconds') : false;

  if (isWithinWindow && maintenanceState.startDate && maintenanceState.endDate && severityString && severityString === MaintenanceSeverity.P1) {
    return <ScheduledMaintenance endDate={maintenanceState.endDate} />;
  }

  return <MaintenanceProvider value={maintenanceState}>{children}</MaintenanceProvider>;
};
