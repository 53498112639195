import { surface } from '@actinc/dls/styles/themeEncourage/colors';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPicture = styled('div')({});

export const HeaderContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(1, 0),
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: theme.spacing(3),
}));

export const ImageDescription = styled(Typography)(() => ({
  color: surface.black[60],
  textAlign: 'center',
  padding: 1,
}));

export const StyledImage = styled('img')(() => ({
  maxInlineSize: '100%',
  blockSize: 'auto',
}));
