enum FEATURE_FLAGS {
  APP_REFRESHER_INTERVAL = 'web_app_refresher_interval',
  ENCOURAGE2_SIGNUP = 'web_encourage2_signup',
  MAINTENANCE_END_DATE = 'maintenance_end_date',
  MAINTENANCE_SEVERITY = 'maintenance_severity',
  MAINTENANCE_START_DATE = 'maintenance_start_date',
  WEB_DEBUG = 'web_debug',
  COLLAGE_PROFILE_PAGE_MAJOR = 'web_college_profile_page_major',
  JMT_TRANSITION_CTA = 'web_jmt_transition_cta_prior_warning',
  JMT_TRANSITION_MOCK_DATE = 'web_jmt_transition_mock_date',
  JMT_TRANSITION_NOTIFICATION = 'web_jmt_transition_notification',
  CMS_ARTICLE = 'web_cms_article',
  LIBRARY_EXPLORE = 'web_library_explore',
  MILITARY_CAREERS = 'web_military_careers',
  PERSONAL_INFO_SETTINGS = 'web_personal_info_settings',
}

export default FEATURE_FLAGS;
