import * as Sentry from '@sentry/nextjs';
import { isError } from 'lodash';

const sentryReactNativeBridgeIncomingEventError = ({
  error,
  event,
}: {
  error: unknown;
  event: Event | MessageEvent;
}) => {
  Sentry?.captureException(error, {
    extra: {
      error: isError(error) && error.message,
      event,
    },
    tags: { feature: 'ReactNativeBridgeIncomingEvent' },
  });
};

export default sentryReactNativeBridgeIncomingEventError;
