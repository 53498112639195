import { CMSContext } from '~/types/cms-context';

export const getCMSUrl = (cmsCtx: CMSContext, path: string, queryString?: string): string => {
  if (cmsCtx.isSiteRequired) {
    return (
      cmsCtx.baseUrl +
      path +
      (queryString ? `?${queryString}&crafterSite=${cmsCtx.site}` : `?crafterSite=${cmsCtx.site}`)
    );
  }
  return cmsCtx.baseUrl + path + (queryString ? `?${queryString}` : ``);
};

export const getStoreUrlForPagePath = (path: string): string => {
  // Example input: /explore/library
  // Example output: /site/website/explore/library/index.xml
  const trimmedPath = path.replace(/^\/+|\/+$/g, '');
  return `/site/website/${trimmedPath}/index.xml`;
};
