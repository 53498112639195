import colors from '@actinc/dls/styles/themeEncourage/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Lottie, { LottieComponentProps, LottieRef } from 'lottie-react';
import React from 'react';

import Link from '~/components/Link';

interface IProps {
  animationData: LottieComponentProps['animationData'];
  href: string;
  isActive?: boolean;
  label: string;
}

const BottomBarItem: React.FC<IProps> = ({ animationData, href, isActive, label }: IProps) => {
  const ref: LottieRef = React.useRef(null);

  React.useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        ref.current?.play();
      }, 250);
    }
  }, [isActive, ref]);

  return (
    <Link href={href}>
      <Stack alignItems="center">
        <Lottie animationData={animationData} autoplay={false} loop={false} lottieRef={ref} style={{ height: 25, width: 25 }} />
        <Typography fontWeight="fontWeightLight" sx={{ color: colors.navy[30] }} variant="body2">
          {label}
        </Typography>
      </Stack>
    </Link>
  );
};

export default BottomBarItem;
