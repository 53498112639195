import dynamic from 'next/dynamic';
import React from 'react';

import { FeatureFlagContext } from '~/context/FeatureFlagsHandler/FeatureFlags';

const WithDebugMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isNonProdEnv = ['feature', 'development', 'stage', 'local'].includes(process.env.NEXT_PUBLIC_ENV || '') || process.env.NODE_ENV === 'development';
  const { featureFlags } = React.useContext(FeatureFlagContext);

  const DebugMenuComponent = React.useMemo(
    () => (isNonProdEnv && featureFlags?.web_debug ? dynamic(() => import('~/components/DebugMenu'), { ssr: false }) : null),
    [isNonProdEnv, featureFlags?.web_debug],
  );

  return (
    <>
      {children}
      {DebugMenuComponent && <DebugMenuComponent />}
    </>
  );
};

export default WithDebugMenu;
