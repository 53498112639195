import { EmptyState } from '@actinc/dls/components/EmptyState';
import { useApolloClient } from '@apollo/client';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';

import { clearTokens } from '~/helpers/authTokens';
import SplashLayout from '~/layouts/SplashLayout';
import MegaphoneSvg from '~/public/static/img/megaphone.svg';

import { StyledContainer, StyledPaper } from '../styles';

interface IProps {
  endDate?: false | '' | moment.Moment;
}

const dateFormat = 'dddd, MMM D [at] h A [CT]';

const ScheduledMaintenance: React.FC<IProps> = ({ endDate }) => {
  const theme = useTheme();
  const client = useApolloClient();
  const displayEndDate = endDate && moment(endDate).format(dateFormat);

  // TODO: This logout logic doesn't handle the mobile app!!
  // Logout and clear apollo client
  clearTokens();
  // `resetStore` because we want to reset the cache and refetch active queries
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  client.resetStore();

  return (
    <SplashLayout
      heading={
        <StyledContainer>
          <StyledPaper>
            <MegaphoneSvg />
            <Container sx={{ paddingBottom: theme.spacing(3) }} />
            <EmptyState
              description={["We're improving your experience. Be back soon! We'll be done by ", <b key="displayEndDate">{displayEndDate}</b>]}
              descriptionTypographyProps={{
                color: 'textPrimary',
              }}
              title="We'll be right back!"
              titleTypographyProps={{
                component: 'h5',
              }}
            />
          </StyledPaper>
        </StyledContainer>
      }
    >
      <Grid container direction="column" rowSpacing={2}>
        <Grid item sx={{ marginTop: theme.spacing(4) }}>
          <Typography gutterBottom variant="h6">
            Need Help?
          </Typography>
          <Typography variant="body1">
            Contact us at{' '}
            <MuiLink component="a" href="mailto:help@encourageme.com" key="Email" sx={{ fontSize: theme.typography.body1.fontSize }} variant="body1">
              help@encourageme.com
            </MuiLink>
          </Typography>
        </Grid>
      </Grid>
    </SplashLayout>
  );
};

export default ScheduledMaintenance;
