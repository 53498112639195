import React from 'react';

/**
 * Creates a composed component from a list of components.
 *
 * The created composed component renders all the components from the list
 * sequentially as children of the other. The immediate composed component child
 * will be the final element of the chain.
 */
const composeComponents = (componentList: React.FC<{ children: React.ReactNode }>[]) => {
  return ({ children }: { children: React.ReactElement }): React.ReactElement => {
    return componentList.reduce((curr, component) => {
      return React.createElement(component, null, curr);
    }, children);
  };
};

export default composeComponents;
